import React from 'react'

import { Link } from 'gatsby';
import bannerImage from "assets/timerbanner.png";
import Check_icon from "assets/check_icon.png"

const ExpiredNotice = ({isOrderInfo}) => {
  const wordData = [
    "Detect languages, identify topics, and get word counts instantly.",
    "Spot tricky phrases before they slow you down.",
    "Assess text difficulty to choose the best translation approach.",
    "Find, define and translate important terms to maintain consistency.",
    "Flag grammar, style, and formatting issues.",
  ];
  return (
    <>{!isOrderInfo && (
      <div className="border border-solid border-[#ebebeb] rounded-sm p-5 sm:p-8 m-2">
        <p className="text-xl sm:text-2xl text-red font-opensans font-semibold text-center">
          Due date has passed
        </p>
      </div>
      )}
        <div className="lg:flex items-center rounded-lg mt-6 bg-[#F0F5FF]">
            <img src={bannerImage} alt="image" className="" />
            <div className="lg:mt-0 mt-4 p-6 h-full">
              <h2 className="text-2xl font-opensans text-[#0A2641] font-bold">
                Work smarter. Translate with confidence.
              </h2>
              <p className="mt-3 text-base font-opensans text-[#0A2641]">
                The{" "}
                <Link to="/tools/pre-translation" className="text-[#1463FF]">
                  Pre-Translation Toolkit{" "}
                </Link>{" "}
                helps you stay efficient by analyzing your text before you begin.
              </p>
              <ul className=" my-6 grid lg:grid-cols-2 gap-3 ">
              {wordData.map((item, i) => (
                <li className="flex gap-4 items-start">
                 <img src={Check_icon} alt="/check_icon" className="pt-1"/>
                  <p className="text-[#575757] font-opensans text-[12px] font-medium">{item}</p>
                </li>
              ))}
              </ul>
              <Link to="/tools">
                <button className="mt-6 bg-[#5B93FF] text-white rounded-lg px-5 py-2 font-opensans text-base font-semibold">
                  Use it for FREE
                </button>
              </Link>
            </div>
          </div>
    </>
  );
};

export default ExpiredNotice
