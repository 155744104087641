// import React, { useEffect, useState } from "react";
// import { useCountdown } from "hooks/useCountdown";
// import DateTimeDisplay from "./DateTimeDisplay";
// import timerbanner from "assets/timerbanner2.png";
// import bannerImage from "assets/timerbanner.png";
// import CounterBanner from "./CounterBanner";
// import { Link } from "@reach/router";
// import ExpiredNotice from "./ExpireCounter";
// import ShowCounter from "./CounterBanner";
// import Loader from "components/loader";



// const CountdownTimer = ({ targetDate, formattedDateUtc }) => {
 
//   const now = new Date();
//   const target = new Date(targetDate);

//   if(now < target){
//    return <ShowCounter
//   // days={days}
//   // hours={hours}
//   // minutes={minutes}
//   // seconds={seconds}
//   // title={title}
//   targetDate={targetDate}
// /> 
//   } 
//   return  <ExpiredNotice />
// //   return now < target;
// //   // const [title, setTitle] = useState(true);
// //   const [loader , setLoader] = useState(true)
// //   const [expired,setExpired] = useState(false)
// //   console.log(loader,expired,"loaderee")
// //   const [days, hours, minutes, seconds] = useCountdown(targetDate);
// //    useEffect(() =>{
// //     if (days + hours + minutes + seconds <= 0){
// //       setExpired(true)
// //       // setLoader(false)
// //     }
// //     setLoader(false)
// //    },[])
// //   console.log(days + hours + minutes + seconds <= 0 , "countervaluess")
// //   if (loader) {
// //     // setTitle(false);
    
// //     return <Loader/> ;
// //   } 
  
// //   if(expired){
// //   return   <ExpiredNotice />
// //   }

// //   if(!loader && !expired) return <ShowCounter
// //   days={days}
// //   hours={hours}
// //   minutes={minutes}
// //   seconds={seconds}
// //   // title={title}
// // />

// };


// export default CountdownTimer;
import React, { useEffect, useState } from "react";
import { useCountdown } from "hooks/useCountdown";
import DateTimeDisplay from "./DateTimeDisplay";
import timerbanner from "assets/timerbanner2.png";
import CounterBanner from "./CounterBanner";
import { Link } from "@reach/router";
import ExpiredNotice from "./ExpireCounter";
import ShowCounter from "./CounterBanner";
import Loader from "components/loader";

const CountdownTimer = ({ targetDate, formattedDateUtc }) => {
  const [loader, setLoader] = useState(true);
  const [expired, setExpired] = useState(false);

  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
 
    if (days + hours + minutes + seconds <= 0 && !expired) {
      setExpired(true); 
      setLoader(false);  
    } else {
      setLoader(false);
    }
  }, [days, hours, minutes, seconds, expired]); 

  if (loader) {
    return <Loader />;
  }


  if (expired) {
    return <ExpiredNotice />;
  }


  return (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

export default CountdownTimer;
