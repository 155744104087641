import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
// import queryString from "query-string";
import moment from "moment";
import CountdownTimer from "./CountdownTimer";


function FinalTimer() {

  const location = useLocation();
  // const queriedTime = queryString.parse(location.search);
  // const { timer } = queriedTime;

  const urlSearchParams = new URLSearchParams(location.search);
  const { timer } = Object.fromEntries(urlSearchParams.entries());
  // console.log(timer);

  const date = moment.parseZone(timer);
  const localDate = moment.parseZone(timer).local();
  // console.log(localDate);

  const formattedDateUtc = localDate.format("DD/MM/YYYY LT [GMT]Z");

  return (
    <div>
      <div className="w-11/12 lg:w-full max-w-6xl mx-auto lg:px-5 py-24">
      
        <p className="text-lg sm:text-2xl text-darkBlue font-secondary font-semibold text-center pb-14">
          Due Date is &nbsp;{" "}
          <span className="text-green-700">{formattedDateUtc}</span>
        </p>
        <CountdownTimer targetDate={date}  formattedDateUtc={formattedDateUtc} />
   
      </div>
    </div>
  );
}

export default FinalTimer;
